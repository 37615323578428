import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {rideService} from "./rideService";

export const getRidesRequest = createAsyncThunk('rides/list', async (action) => {
    return await rideService.getRides(action.page, action.pageSize, action.order, action.filter)
});

export const ridesSlice = createSlice({
    name: 'rides',
    initialState: {
        loading: false,
        rides : null,
        filter: '',
        order: 'creationDate,desc',
        pageSize: null,
        page: 0,
        errorMessage: null,
    },
    reducers: {
        applyFilter: (state, action) => {
            state.page = 0;
            state.filter = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        },
        changePage: (state, action) => {
            console.log("action " + action.payload)
            state.page = action.payload;
        },
        changePageSize: (state, action) => {
            state.page = 0;
            state.pageSize = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRidesRequest.pending, state => {
            state.loading = true;
        });

        builder.addCase(getRidesRequest.fulfilled, (state,action) => {
            state.loading = false;
            state.rides = action.payload
        });

        builder.addCase(getRidesRequest.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload;
        });
    }
})

export const { applyFilter, setOrder, changePage, changePageSize } = ridesSlice.actions;

export default ridesSlice.reducer