import React, {useEffect} from "react";
import BlankDashboardPage from "../Blank";
import {Badge, Button} from "reactstrap";
import DataTable from "../../components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {changePage, changePageSize, getUsersRequest, applyFilter} from "../../features/user/usersSlice";
import {Link} from "react-router-dom";



function UserList(props) {
    let users = useSelector((state) => state.usersState.users);
    const dispatch = useDispatch()
    const filter = useSelector((state) => state.usersState.filter);
    const order = useSelector((state) => state.usersState.order);
    const page = useSelector((state) => state.usersState.page);
    const pageSize = useSelector((state) => state.usersState.pageSize);


    useEffect(() => {
        dispatch(getUsersRequest({filter, order, page, pageSize}));
    }, [filter, order, page, pageSize, dispatch]);

    function onPageChanged(page) {
        console.log("change page " + page)
        dispatch(changePage(page - 1));
    }

    function pageSizeChanged(event) {
        dispatch(changePageSize(event.target.value));
    }

    function onSearchTyped(search) {
        dispatch(applyFilter(search))
    }


    return (
            <BlankDashboardPage title={"Utilisateurs"} subTitle={"Page d'administration des utilisateurs"}>
                <DataTable pageSize={pageSize}
                           pageSizeOptions={[5, 10, 20, 30, 50, 100]}
                           onPageSizeSelected={pageSizeChanged}
                           onSearchTyped={onSearchTyped}
                           onPageChanged={onPageChanged}
                           columns={['Prénom', 'Nom', 'Numéro de téléphone', 'Roles', 'État', 'Actions']}
                           data={users}
                           rowMapper={renderRow}
                    />
            </BlankDashboardPage>
        );

    function renderRow(row) {
        return (
            <tr key={row.id}>
            <td>{row.firstname}</td>
            <td>{row.lastname}</td>
            <td>+224 {row.phoneNumber.networkExtension} {row.phoneNumber.lineNumber}</td>
            <td>{row.roles.map(mapRole)}</td>
            <td>{row.active == true ? 'Activé': 'Désactivé'}</td>
            <td>
                <Link className="btn btn-sm btn-info" to={ {
                    pathname: `/users/edit/${row.id}`,
                    state: row
                }}>Modifier {}</Link>{' '}
                <Button size={"sm"} color="danger">Supprimer {}</Button>{' '}
            </td>

        </tr>
        );
    }

    function mapRole(role) {
        switch (role) {
            case 'ROLE_CLIENT':
                return <><Badge key={role} color="info">Client</Badge>{' '}</>;
            case 'ROLE_ADMIN':
                return <><Badge  key={role} color="danger">Administrateur</Badge>{' '}</>;
            default:
                 return <><Badge  key={role} color="primary">Chauffeur</Badge>{' '}</>

        }
    }
}



export default UserList;