import api from "../../config/api";
import axios from "axios";
import authHeaders from "../auth/authHeader";
import handleResponse from "../../config/http-response-handler";


export const userService = {
    getUsers,
    getDrivers,
    addUser,
    editUser
};


function getUsers(pageNumber, pageSize, sortingSpec, searchSpec) {
    console.log("load user")
    return axios.get(`${api.url}/admin/users?page=${pageNumber}&size=${pageSize}&sort=${sortingSpec}&search=${searchSpec}`, {timeout: 200000, headers: authHeaders})
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleResponse);
}

function getDrivers() {
    console.log("load drivers")
    return axios.get(`${api.url}/admin/users/drivers`, {timeout: 200000, headers: authHeaders})
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleResponse);
}

function addUser(user) {
    return axios.post(`${api.url}/admin/users`, user, {timeout: 200000, headers: authHeaders})
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleResponse);
}

function editUser(user) {
    return axios.post(`${api.url}/admin/users/${user.id}`, user, {timeout: 200000, headers: authHeaders})
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleResponse);
}



