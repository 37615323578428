import { createSlice } from '@reduxjs/toolkit';

export const menuOpenCloseSlice = createSlice({
    name: 'menuState',
    initialState: {
        menuOpen: false
    },
    reducers: {
        toggleOpen: (state, action) => {
           state.menuOpen = !state.menuOpen;
        },
    },
})

export const { toggleOpen } = menuOpenCloseSlice.actions

export default menuOpenCloseSlice.reducer

