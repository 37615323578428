import { configureStore } from '@reduxjs/toolkit'

import authenticationReducer from "../features/auth/authenticationSlice";
import menuOpenCloseReducer from "../features/menu/MenuOpenCloseSlice";
import usersReducer from "../features/user/usersSlice";
import driversReducer from "../features/user/driversSlice";
import ridesReducer from "../features/ride/ridesSlice";
import statsReducer from "../features/stats/statsSlice";

export default configureStore({
    reducer: {
        menuState: menuOpenCloseReducer,
        authenticationState: authenticationReducer,
        usersState: usersReducer,
        driversState: driversReducer,
        ridesState: ridesReducer,
        statsState: statsReducer
    },
})