import React, {useState} from "react";
import BlankDashboardPage from "../Blank";
import {Card, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import {useLocation} from "react-router-dom/cjs/react-router-dom";
import {userService} from "../../features/user/userService";


import toast from 'react-hot-toast';
function EditUser(props) {
    let location = useLocation();
    const [state, setState] = useState( initialState(location.state));
    const [redirect] = useState();


    function initialState(state) {
        if(!state) return {};

        let adaptedState = {
            ...state,
            ["phoneNumber"]: phoneNumberAsString(state.phoneNumber),
        };

        if(state.identity) {
            adaptedState = {
                ...adaptedState,
                ["identityNumber"]: state.identity.number,
                ["identityExpirationDate"]: state.identity.expirationDate.substring(0, 10),
                ["identityDocumentLink"]: state.identity.documentLink
            };
        }

        if(state.vehicleRegistration) {
            adaptedState = {
                ...adaptedState,
                ["vehiclePlateNumber"]: state.vehicleRegistration.plateNumber,
                ["vehicleRegistrationNumber"]: state.vehicleRegistration.number,
                ["vehicleRegistrationModel"]: state.vehicleRegistration.model,
                ["vehicleDocumentLink"]: state.vehicleRegistration.documentLink,
                ["vehicleExpirationDate"]: state.vehicleRegistration.expirationDate.substring(0, 10),
            };
        }


        return adaptedState;
    }

    function handleChange(event) {
        const {target} = event;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const {name} = target;

        setState({
            ...state,
            [name]: value,
        });
    }

    function getMultiSelectValue(event) {
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        return opts;
    }

    function handleRoleSelect(event) {
        const {target} = event;
        let value = getMultiSelectValue(event);

        const {name} = target;
        let newState = state;
        if(value === 'ROLE_DRIVER') {
           newState =  {
            ...state,
                ['identity']: {},
                ['vehicleRegistration']: {}            }
        }

        setState({
            ...newState,
            [name]: value
        });
    }

    function phoneNumberAsString(phoneNumber) {
        if(! phoneNumber) {
            return '';
        }
        if(typeof phoneNumber === 'string'){
            return phoneNumber;
        }
        return phoneNumber.networkExtension +''+phoneNumber.lineNumber;
    }

    function addNewUser() {
        userService.addUser({...state, phoneNumber: phoneNumberAsString(state.phoneNumber)})
            .then(value => {
                toast.success(`L'utilisateur ${state.firstname} ${state.lastname} a bien été ajouté!`);
                window.location.href = '/users';
            }).catch(reason => {
            if (reason.data.message == "user.already.registered") {
                toast.error("Le numéro de téléphone est déjà utilisé!");
            } else {
                toast.error("Une erreur s'est produite. Verifier les information fournis");
            }
        });
    }
    function editExistingUser() {
        userService.editUser({...state, phoneNumber: phoneNumberAsString(state.phoneNumber)})
            .then(value => {
                toast.success(`L'utilisateur ${state.firstname} ${state.lastname} a bien été mis à jour!`);
                window.location.href = '/users';
            }).catch(reason => {
            toast.error("Une erreur s'est produite. Verifier les information fournis");
        });
    }

    function handleSubmit(event) {
        event.preventDefault();
        if(state.id){
            editExistingUser();
        } else {
            addNewUser();
        }
    }

    function hasRole(role) {
        let includes = state.roles && state.roles.includes(role);
        return includes;
    }

    function firstnameValid() {
        return state.firstname && state.firstname.length >= 2
    }

    function lastnameValid() {
        return state.lastname && state.lastname.length >= 2
    }

    function phoneNumberValid() {
        return state.phoneNumber && /^\d{9}$/.test(state.phoneNumber)
    }

    function roleValid() {
        return state.roles && state.roles.length >= 1;
    }

    function formValid() {
        return firstnameValid()
            && lastnameValid()
            && phoneNumberValid()
            && driverRoleIsValid();
    }


    function driverRoleIsValid() {
        return !hasRole('ROLE_DRIVER') ||
            (identityNumberValid() && identityDocumentLinkValid() && identityExpirationDateValid()
                && vehicleDocumentLinkValid() && vehicleRegistrationNumberValid() && vehicleRegistrationModelValid() && vehicleExpirationDateValid()
                && vehiclePlateNumberValid()
            )
            ;
    }

    function identityNumberValid() {
        return state.identityNumber > 0
    }

    function identityExpirationDateValid() {
        return /^\d{4}-\d{2}-\d{2}$/.test(state.identityExpirationDate);
    }

    function vehicleExpirationDateValid() {
        return /^\d{4}-\d{2}-\d{2}$/.test(state.vehicleExpirationDate);
    }



    function identityDocumentLinkValid() {
        return state.identityDocumentLink;
    }

    function vehicleDocumentLinkValid() {
        return state.vehicleDocumentLink;
    }



    function vehiclePlateNumberValid() {
       return state.vehiclePlateNumber;
    }

    function vehicleRegistrationNumberValid() {
        return state.vehicleRegistrationNumber;
    }

    function vehicleRegistrationModelValid() {
        return state.vehicleRegistrationModel;
    }

    if (redirect) return <Redirect to={redirect}/>
    return (
        <>
            <BlankDashboardPage title="Ajouter un utilisateur" subTitle="Ajout de client ou de chauffeur">
                <Col>
                    <Card>
                        <Form style={{padding: "16px", width: "100%"}} onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="firstname">Prénom</Label>
                                        <Input
                                            id="firstname"
                                            name="firstname"
                                            placeholder="Exemple: Macky"
                                            type="text"
                                            value={state.firstname}
                                            onChange={handleChange}
                                            invalid={!firstnameValid()}
                                            valid={firstnameValid()}
                                        />

                                        <FormFeedback invalid={!firstnameValid()}>
                                            Le prénom n'est pas valide
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="lastname">Nom</Label>
                                        <Input
                                            id="lastname"
                                            name="lastname"
                                            placeholder="Exemple: Camara"
                                            type="text"
                                            value={state.lastname}
                                            onChange={handleChange}
                                            invalid={!lastnameValid()}
                                            valid={lastnameValid()}
                                        />

                                        <FormFeedback invalid={!lastnameValid()}>
                                            Le nom n'est pas valide
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="phoneNumber">Numéro de téléphone</Label>
                                <InputGroup>
                                    <InputGroupText>+224</InputGroupText>
                                    <Input
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        placeholder="Exemple: 622000000"
                                        type="number"
                                        value={phoneNumberAsString(state.phoneNumber)}
                                        onChange={handleChange}
                                        invalid={!phoneNumberValid()}
                                        valid={phoneNumberValid()}
                                    />

                                    <FormFeedback invalid={!phoneNumberValid()}>
                                        Le numéro de téléphone doit avoir 9 chiffre
                                    </FormFeedback>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="roles">Profil (plusieurs choix possible, appuyer sur CTRL)</Label>
                                <Input id="roles" multiple name="roles" value={state.roles} type="select"
                                       onChange={handleRoleSelect} valid={roleValid()} invalid={!roleValid()}>
                                    <option value="ROLE_ADMIN">Administrateur</option>
                                    <option value="ROLE_DRIVER">Chauffeur</option>
                                    <option value="ROLE_CLIENT">Client</option>
                                </Input>
                                <FormFeedback invalid={roleValid()}>
                                    Vous devez attribuer au moins un role à l'utilisateur
                                </FormFeedback>
                            </FormGroup>
                            {hasRole("ROLE_DRIVER") &&
                                <FormGroup tag="fieldset">
                                    <legend>Information complementaire</legend>
                                    <h5>Pièce d'identité</h5>
                                    <Row>
                                        <Col md={12} xl={2} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="identityNumber">
                                                    Numéro
                                                </Label>
                                                <Input name="identityNumber" value={state.identityNumber}
                                                       type="number"
                                                       onChange={handleChange}
                                                       valid={identityNumberValid()}
                                                       invalid={!identityNumberValid()}/>
                                                <FormFeedback invalid={!identityNumberValid()}>
                                                    Vous devez renseigner le numéro de la pièce d'indentité
                                                </FormFeedback>
                                            </FormGroup>

                                        </Col>
                                        <Col  md={12} xl={2} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="identityExpirationDate">
                                                    Date d'expiration
                                                </Label>
                                                <Input name="identityExpirationDate"
                                                       value={state.identityExpirationDate}
                                                       type="date" onChange={handleChange}
                                                       valid={identityExpirationDateValid()}
                                                       invalid={!identityExpirationDateValid()}
                                                />

                                                <FormFeedback invalid={!identityExpirationDateValid()}>
                                                    Vous devez renseigner la date d'expiration de la pièce d'identité
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col  md={12} xl={2} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="identityDocumentLink">
                                                    Référence du document
                                                </Label>
                                                <Input name="identityDocumentLink"
                                                       value={state.identity && state.identity.documentLink}
                                                       type="text" onChange={handleChange}
                                                       valid={identityDocumentLinkValid()}
                                                       invalid={!identityDocumentLinkValid()}
                                                />

                                                <FormFeedback invalid={!identityDocumentLinkValid()}>
                                                    Vous devez renseigner la date d'expiration de la pièce d'identité
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <h5>Véhicule</h5>
                                    <Row>
                                        <Col md={12} xl={2} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="vehiclePlateNumber">
                                                    Numéro d'immatriculation
                                                </Label>
                                                <Input name="vehiclePlateNumber"
                                                       value={state.vehiclePlateNumber}
                                                       placeholder="Exemple: 000000" type="text"
                                                       onChange={handleChange}
                                                       valid={vehiclePlateNumberValid()}
                                                       invalid={!vehiclePlateNumberValid()}
                                                />

                                                <FormFeedback invalid={!vehiclePlateNumberValid()}>
                                                    Vous devez renseigner le numéro d'immatriculation du véhicule
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col  md={12} xl={2} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="vehicleRegistrationNumber">
                                                    Numéro d'enrégistrement
                                                </Label>
                                                <Input name="vehicleRegistrationNumber"
                                                       value={state.vehicleRegistration && state.vehicleRegistration.number}
                                                       placeholder="Exemple: 111111"
                                                       type="number" onChange={handleChange}
                                                       valid={vehicleRegistrationNumberValid()}
                                                       invalid={!vehicleRegistrationNumberValid()}
                                                />

                                                <FormFeedback invalid={!vehicleRegistrationNumberValid()}>
                                                    Vous devez renseigner le numéro d'enrégistrement
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col  md={12} xl={2} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="vehicleRegistrationModel">
                                                    Model
                                                </Label>
                                                <Input name="vehicleRegistrationModel"
                                                       value={state.vehicleRegistrationModel}
                                                       placeholder="Exemple: TVS"
                                                       type="text" onChange={handleChange}
                                                       valid={vehicleRegistrationModelValid()}
                                                       invalid={!vehicleRegistrationModelValid()}
                                                />

                                                <FormFeedback invalid={!vehicleRegistrationNumberValid()}>
                                                    Vous devez renseigner le model du véhicule
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col  md={12} xl={2} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="vehicleExpirationDate">
                                                    Date d'enrégistrement
                                                </Label>
                                                <Input name="vehicleExpirationDate"
                                                       value={state.vehicleExpirationDate}
                                                       type="date"
                                                       onChange={handleChange}
                                                       valid={vehicleExpirationDateValid()}
                                                       invalid={!vehicleExpirationDateValid()}
                                                />

                                                <FormFeedback invalid={!vehicleExpirationDateValid()}>
                                                    Vous devez renseigner la date d'enregistrement du véhicule
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>

                                        <Col  md={12} xl={2} sm={12} xs={12}>
                                            <FormGroup>
                                                <Label for="vehicleDocumentLink">
                                                    Référence du document
                                                </Label>
                                                <Input name="vehicleDocumentLink"
                                                       value={state.vehicleDocumentLink}
                                                       type="text" onChange={handleChange}
                                                       valid={vehicleDocumentLinkValid()}
                                                       invalid={!vehicleDocumentLinkValid()}
                                                />
                                                <FormFeedback invalid={!vehicleDocumentLinkValid()}>
                                                    Vous devez renseigner la reférence vers le document du véhicule
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </FormGroup>
                            }

                            <FormGroup check>
                                <Input type="checkbox" name="activate" onChange={handleChange}/>
                                {' '}
                                <Label check>Activé</Label>

                            </FormGroup>
                            <br/>
                            <Link to="/users" className="btn btn-secondary">
                                Annuler
                            </Link>
                            {' '}
                            <input disabled={!formValid()} type={"submit"} value={"Enrégistrer"}
                                   className="btn btn-primary"/>
                        </Form>
                    </Card>
                </Col>

            </BlankDashboardPage>
        </>
    )
}

export default EditUser;
