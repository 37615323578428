import api from "../../config/api";
import axios from "axios";
import authHeaders from "../auth/authHeader";
import handleResponse from "../../config/http-response-handler";


export const statsService = {
    getEntitiesCount,
};


function getEntitiesCount() {
    return axios.get(`${api.url}/admin/statistics/entities`, {timeout: 200000, headers: authHeaders})
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleResponse);
}

