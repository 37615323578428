import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

//Pages
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import {useSelector} from "react-redux";
import ResetPassword from "./pages/ResetPassword";
import UserList from "./pages/User/UserList";
import BlankDashboardPage from "./pages/Blank";
import RideList from "./pages/Ride/RideList";
import EditUser from "./pages/User/EditUser";

const PrivateRoute = ({ component: Component, ...rest }) => {

    const loggedIn = useSelector((state) => state.authenticationState.loggedIn)

    return (
        <Route
            {...rest}
            render={(props) => loggedIn ? (
                <Component {...props} />
                ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )}
            />
    )
}

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/login" component={SignIn} />
            <PrivateRoute exact path="/" component={Dashboard} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <PrivateRoute path="/users/edit(/:id)?" component={EditUser} />
            <PrivateRoute path="/users/new" component={EditUser} />
            <PrivateRoute path="/users" component={UserList} />
            <PrivateRoute path="/rides" component={RideList} />
            <PrivateRoute path="/statistics" component={BlankDashboardPage} />
            <Route path="*" component={NotFound} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
