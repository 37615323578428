import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

/****
 * To add page like loginPage and Signup page
 */
class PublicPage extends Component {
    componentWillMount(){
        document.getElementById('body').className='bg-gradient-primary'
    }

    render() {
        return (
            <div className="container">
                <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                        {/* <!-- Nested Row within Card Body --> */}
                        <div className="row">
                            {this.props.children}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(PublicPage);