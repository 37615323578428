import api from "../../config/api";
import axios from "axios";

export const authenticationService = {
    login,
    logout,
    forgetPassword,
    resetPassword,
    rememberMe
};

function rememberMe(value) {
    localStorage.setItem('rememberMe', value);
};

function login(login, password) {
    return axios.post(`${api.url}/auth/login`, JSON.stringify({ login, password }),{ timeout: 200000, headers: {'Content-Type': 'application/json'},})
        .then(handleResponse)
        .then(data => {
            localStorage.setItem('accessToken', JSON.stringify(data.accessToken));
            return data;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('accessToken');
}

function forgetPassword(phoneNumber) {
    return axios.post(`${api.url}/auth/password/forget`, JSON.stringify({ phoneNumber }),{ timeout: 200000, headers: {'Content-Type': 'application/json'},})
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function resetPassword(phoneNumber, password, passwordConfirmation, code) {
    return axios.post(`${api.url}/auth/password/reset`, JSON.stringify({ phoneNumber, password, passwordConfirmation, code}),{ timeout: 200000, headers: {'Content-Type': 'application/json'},})
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    const data = response.data;
    if (response.status !== 200 && response.status !== 204 ) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            // window.location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    return data;
}