import BlankDashboardPage from "../Blank";
import {Badge, Button, ButtonGroup, Card, CardBody, Col, Row} from "reactstrap";
import DataTable from "../../components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {applyFilter, changePage, changePageSize, getRidesRequest} from "../../features/ride/ridesSlice";
import moment from "moment";
import React, {useEffect, useRef, useState} from 'react';

import {MdSync} from "react-icons/md";
import {setInterval} from "timers";

import RideAutoRefreshButton from "./RideAutoRefreshButton";


const pageSizeOptions = Array(5, 10, 20, 30, 50, 100);
const statusFilterOptions = [
    {
        statusLabel: 'Aucun filtre',
        filterValue: '',
        color: 'primary'
    },
    {
        statusLabel: 'Demande en attente de conducteur',
        filterValue: 'status=PRICE_ACCEPTED',
        color: 'danger'
    },
    {
        statusLabel: 'Nouvelle demande',
        filterValue: 'status=REQUESTED',
        color: 'info'
    },
    {
        statusLabel: 'Annul',
        filterValue: 'status=REQUESTED',
        color: 'info'
    },
    {
        statusLabel: 'Annuler par le client',
        filterValue: 'status=PASSENGER_CANCELED',
        color: 'info'
    }, {
        statusLabel: 'Annuler par le chauffeur',
        filterValue: 'status=DRIVER_CANCELED',
        color: 'info'
    }, {
        statusLabel: 'Expirer',
        filterValue: 'status=EXPIRED',
        color: 'info'
    }, {
        statusLabel: 'En cours',
        filterValue: 'status=RIDE_STARTED',
        color: 'info'
    },
]


function RideList(props) {
    const dispatch = useDispatch()
    let rides = useSelector((state) => state.ridesState.rides);
    const filter = useSelector((state) => state.ridesState.filter);
    const order = useSelector((state) => state.ridesState.order);
    const page = useSelector((state) => state.ridesState.page);
    const pageSize = useSelector((state) => state.ridesState.pageSize);
    const [filterSelected, setFilterSelected] = useState({});
    let [autoRefresh, setAutoRefresh] = useState(false);
    const refreshInterval = 2000;

    const autoRefreshTimout = useRef();


    useEffect(() => {
        reloadList();
    }, [filter, order, page, pageSize, dispatch]);


    useEffect(() => {
        if(autoRefresh) {
            console.log("Stop 1 auto refresh")
            autoRefreshTimout.current = setInterval(reloadList, refreshInterval);
        } else {
            console.log("Stop 1 auto refresh")
            clearInterval(autoRefreshTimout.current)
            autoRefreshTimout.current = null;
        }

        return () => {
            console.log("Stop auto refresh")
            clearInterval(autoRefreshTimout.current)
            autoRefreshTimout.current = null;
        }
    }, [autoRefresh]);

    function pageSizeChanged(event) {
        dispatch(changePageSize(event.target.value));
    }

    const reloadList = () => {
        console.log("Reaload list")
        dispatch(getRidesRequest({filter, order, page, pageSize}));
    }

    function pageChanged(page)   {
        dispatch(changePage(page - 1));
    }

    function toggleAutoRefresh(e) {
        e.preventDefault()
        setAutoRefresh((isRunning) => !isRunning);
    }

    function onSearchTyped(search) {
        dispatch(applyFilter(search))
    }



    return (
        <BlankDashboardPage title={"Courses"} subTitle={"Page d'administration des courses"}>
            <br/>
            <br/>

            <Col>
                <Card>
                    <CardBody>
                        <h4>Filtrer les courses par status</h4>
                        <ButtonGroup>
                            {statusFilterOptions.map((statusFilter, index) => <Button
                                size="sm"
                                key={index}
                                color={statusFilter.color}
                                active={filterSelected.filterValue == statusFilter.filterValue}
                                onClick={() => {
                                    setFilterSelected(statusFilter)
                                    dispatch(applyFilter(statusFilter.filterValue))
                                }}>{statusFilter.statusLabel}</Button>)}
                        </ButtonGroup>
                        <br/>
                        <br/>
                        <Button color="success" size="sm" onClick={reloadList}><MdSync size="25"/></Button> {' '}
                        <RideAutoRefreshButton/>
                    </CardBody>
                </Card>

            </Col>

            <DataTable pageSize={pageSize}
                       pageSizeOptions={[5, 10, 20, 30, 50, 100]}
                       onPageSizeSelected={pageSizeChanged}
                       onPageChanged={pageChanged}
                       onSearchTyped={onSearchTyped}
                       columns={['Trajet', 'Status', 'Numéro client', 'Numéro chauffeur', 'Prix', 'Crée le', 'Expire le', 'Debut de course le', 'Fin de course le']}
                       data={rides}
                       rowMapper={renderRow}
                       filterOptions={<Row>
                           <Col md={3}>
                               <Badge color="danger">Course en attente de chauffeur</Badge>
                           </Col>
                       </Row>}
            />
        </BlankDashboardPage>
    );


    function renderRow(ride) {
        return <tr key={ride.id}>
            <td><a target="_blank"
                   href={`https://www.google.com/maps/dir/${ride.pickup.latitude},${ride.pickup.longitude}/${ride.dropOff.latitude},${ride.dropOff.longitude}`}>Voir
                sur la carte</a></td>
            <td>{ride.status}</td>
            <td>{ride.client.phoneNumber.networkExtension}{ride.client.phoneNumber.lineNumber} ({ride.client.firstname} {ride.client.lastname})</td>
            {ride.driver &&
                <td>{ride.driver.phoneNumber.networkExtension}{ride.driver.phoneNumber.lineNumber} ({ride.driver.firstname} {ride.driver.lastname})</td>
            }
            {!ride.driver &&
                <td>Pas de chauffeur pour cette course</td>
            }
            <td>{ride.price.amount} {ride.price.currency}</td>
            <td><DateFormat date={ride.creationDate}/></td>
            <td><DateFormat date={ride.expirationDate}/></td>
            <td><DateFormat date={ride.startDate}/></td>
            <td><DateFormat date={ride.endDate}/></td>

           
           
           

        </tr>;
    }
}


function DateFormat(props) {
    return props.date && moment(props.date).format('MM/DD/YYYY HH:mm:ss') || 'N/A';
}

export default RideList;