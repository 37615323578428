import React, {useState} from 'react';
import {Col, Container, Input, InputGroup, InputGroupAddon, Row, Table} from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import {FaArrowDown, FaArrowUp} from "react-icons/fa6";

export default function DataTable(props) {
    const [sort, setSort] = useState(0);

    function onSearchTyped(event) {
        if (props.onSearchTyped) {
            props.onSearchTyped(event.target.value)
        }
    }


    if (!(props.data && props.data.content)) {
            return '';
        }

    let data = props.data;
        return (
            <Container style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <Row>
                    <Col sm={6} md={5} xl={2}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                Montrer
                            </InputGroupAddon>
                            <Input type="select" value={props.pageSize || 10} name="pageSize" id="pageSize"
                                   onChange={props.onPageSizeSelected}>
                                {props.pageSizeOptions.map((option, index) => <option
                                    key={index}>{option}</option>)}
                            </Input>
                            <InputGroupAddon addonType="append">
                                lignes
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <br/>
                    <br/>
                    <Col sm={6} md={5} xl={3}>
                        <InputGroup>
                            <Input type="text" value={props.search} name="search" id="search"
                                   placeholder="Rechercher"
                                   onChange={onSearchTyped}>
                            </Input>
                        </InputGroup>
                    </Col>
                </Row>
                <br/>
                <Table responsive bordered striped>
                    <thead>
                    <tr>
                        {props.columns.map((column, index) =>
                            <SortableTh selected={sort}
                                        value={index}
                                        key={index}
                                        onClick={(value) => setSort(value)}
                            >
                                {column}
                            </SortableTh>)
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {props.data.content.map(props.rowMapper)}
                    {data && data.numberOfElements < 1 && <tr>
                        <td colSpan={props.columns.length} className="text-center">Aucun élément disponible</td>
                    </tr>}
                    </tbody>
                </Table>
                <Col md={12} xs={12} xl={3} sm={6}>
                    {data && data.numberOfElements > 1 && <p> Affiche de {data.pageable.pageNumber  * data.pageable.pageSize + 1} à {data.pageable.pageNumber  * data.pageable.pageSize + data.numberOfElements } sur {data.totalElements} éléments</p>}
                </Col>
                <Col md={12} xs={12} xl={3} sm={6}>
                    <PaginationComponent
                        firstPageText="Premier"
                        nextPageText="Suivant"
                        previousPageText="Précédent"
                        lastPageText={'Dernier'}
                        totalItems={data.totalElements}
                        pageSize={data.pageable.pageSize}
                        onSelect={props.onPageChanged}/>
                </Col>
            </Container>
        );
}

function SortableTh(props) {
    const [direction, setDirection] = useState("desc")

    function isSelected() {
        // return props.selected === props.value;
        return false
    }

    function handleOnClick(e) {
        props.onClick(props.value);
        setDirection(direction === "asc" ? "desc" : "asc")
    }

    return <td onClick={handleOnClick}>
        {props.children} {isSelected()}
        {isSelected() &&
            <>
                {!(direction === "desc") && <FaArrowDown className="float-right"/>}
                {direction === "desc" && <FaArrowUp className="float-right"/>}
            </>
        }
    </td>
}