import React, { useState, useEffect, useRef } from "react";
import {getRidesRequest} from "../../features/ride/ridesSlice";
import {useDispatch, useSelector} from "react-redux";
import {Button, ButtonGroup, Input} from "reactstrap";

export default function RideAutoRefreshButton() {
    const dispatch = useDispatch()
    const [isAutoReloadEnabled, setIsAutoRefreshEnabled] = useState(false);

    const filter = useSelector((state) => state.ridesState.filter);
    const order = useSelector((state) => state.ridesState.order);
    const page = useSelector((state) => state.ridesState.page);
    const pageSize = useSelector((state) => state.ridesState.pageSize);

    const refreshTimeoutRef = useRef();

    useEffect(() => {
        return () => clearInterval(refreshTimeoutRef.current);
    }, []);

    // useEffect that start/stop interval on flag change
    useEffect(() => {
        clearInterval(refreshTimeoutRef.current);
        refreshTimeoutRef.current = null;

        if (isAutoReloadEnabled) {
            refreshTimeoutRef.current = setInterval(
                () => {
                    reloadList();
                },
                1000
            );
        }

    }, [filter, order, page, pageSize, isAutoReloadEnabled]);

    function toggleAutoRefresh() {
        setIsAutoRefreshEnabled((isRunning) => !isRunning);
    }

    const reloadList = () => {
        dispatch(getRidesRequest({filter, order, page, pageSize}));
    }

    return (
        <>
            <Button color={isAutoReloadEnabled? 'success': 'danger'} size="sm" onClick={toggleAutoRefresh}>
                Mise à jour auto {isAutoReloadEnabled? 'activé': 'désactivé'}
            </Button>
        </>

    );
}
