import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {statsService} from "./statsService";


export const getEntitiesCountRequest = createAsyncThunk('users/list', async (_) => {
    return await statsService.getEntitiesCount()
});

export const statsSlice = createSlice({
    name: 'stats',
    initialState: {
        loading: false,
        userCount : 0,
        driverCount : 0,
        clientCount : 0,
        rideCount: 0,
        errorMessage: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEntitiesCountRequest.pending, state => {
            state.loading = true;
        });

        builder.addCase(getEntitiesCountRequest.fulfilled, (state,action) => {
            state.loading = false;
            state.userCount = action.payload.userCount
            state.rideCount = action.payload.rideCount
            state.driverCount = action.payload.driverCount
            state.clientCount = action.payload.clientCount
        });

        builder.addCase(getEntitiesCountRequest.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload;
        });
    }
})


export default statsSlice.reducer