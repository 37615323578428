import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {authenticationService} from "./authenticationService";

export const loginRequest = createAsyncThunk("auth/login", async (action) => {
    const response = await authenticationService.login(action.login, action.password)
    return response.accessToken;
});

export const forgetPasswordRequest = createAsyncThunk("auth/password/forget", async (action) => {
    const response = await authenticationService.forgetPassword(action.phoneNumber)
    return response;
});

export const resetPasswordRequest = createAsyncThunk("auth/password/reset ", async (action) => {
    const response = await authenticationService.resetPassword(action.phoneNumber, action.password,action.passwordConfirmation,action.code)
    return response;
});



let accessToken = localStorage.getItem('accessToken');

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
        loggedIn: !!accessToken,
        loading: false,
        token: accessToken,
        passwordResetAsked: false,
        errorMessage: null
    },
    reducers: {
        rememberMe: (state, action) => {
            state.rememberMe = action.payload;
           authenticationService.rememberMe(state.rememberMe);
        },

        logout: (state, action) => {
            state.loading = false;
            state.rememberMe = false;
            state.loggedIn = false;
            state.token = null;
            state.errorMessage = null;
            state.resetPasswordErrorMessage = null;
            authenticationService.logout();
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginRequest.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(loginRequest.fulfilled, (state, action) => {
            state.loggedIn = true;
            state.token = action.payload;
            state.loading = false;
            state.errorMessage = null
            window.location = '/'
        });

        builder.addCase(loginRequest.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage = "Identifiant ou mot de passe incorrect";
        });

        builder.addCase(forgetPasswordRequest.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(forgetPasswordRequest.fulfilled, (state, action) => {
            state.loading = false;
            state.passwordResetAsked = true;
            state.errorMessage = null;
        });

        builder.addCase(forgetPasswordRequest.rejected, (state, action) => {
            state.loading = false;
            state.resetPasswordErrorMessage = "Numéro de téléphone incorrect";
        });

        builder.addCase(resetPasswordRequest.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(resetPasswordRequest.fulfilled, (state, action) => {
            state.loading = false;
            state.resetPasswordErrorMessage = null;
            window.location = '/login'
        });

        builder.addCase(resetPasswordRequest.rejected, (state, action) => {
            state.loading = false;
            state.resetPasswordErrorMessage = "Les informations fournis sont incorrects merci de vérifier s'il vous plaît.";
        });
    }
})

export const { logout } = authenticationSlice.actions

export default authenticationSlice.reducer

