import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {loginRequest} from "../../features/auth/authenticationSlice";


function LoginButton(props) {
    const loading = useSelector((state) => state.authenticationState.loading)

    const dispatch = useDispatch();
    function handleClick(event) {
        event.preventDefault();
        dispatch(loginRequest({login : props.username,  password :props.password}));
    }
   return (

       <button disabled={loading} onClick={handleClick} className="btn btn-primary btn-user btn-block">
           {loading ? (<><div className="spinner-border" role="status">
               <span className="sr-only">Patientez...</span>
           </div> <span>Patientez...</span></>) : "Se connecter"}
       </button>
   );
}

function LoginError() {
    const errorMessage = useSelector((state) => state.authenticationState.errorMessage)
    if(errorMessage) {
        return <div className={"alert alert-danger"}>{errorMessage}</div>
    }
    return '';
}
class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        document.getElementById('body').className = 'bg-gradient-primary';
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        return (
            <div className="container">
                {/* <!-- Outer Row --> */}
                <div className="row justify-content-center">
        
              <div className="col-xl-10 col-lg-12 col-md-9">
        
                <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                    {/* <!-- Nested Row within Card Body --> */}
                    <div className="row">
                      <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                      <div className="col-lg-6">
                        <div className="p-5">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">Content de te revoir !</h1>
                          </div>
                          <form className="user">
                            <div className="form-group">
                              <input type="email"  name="username" className="form-control form-control-user" onChange={this.handleChange}  placeholder="Entrer l'adresse e-mail..."/>
                            </div>
                            <div className="form-group">
                              <input type="password"  name="password" className="form-control form-control-user" onChange={this.handleChange} placeholder="Mot de passe"/>
                            </div>
                              <LoginError />
                              <LoginButton username={this.state.username} password={this.state.password}/>
                            <hr/>
                          </form>
                          <div className="text-center">
                              <Link className="small" to={"/reset-password"}>Mot de passe oublié ?</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        
              </div>
        
            </div>
        
          </div>
        )
    }
}

export default withRouter(SignIn);