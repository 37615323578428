import React from 'react';
import {Link} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {toggleOpen} from "../../../features/menu/MenuOpenCloseSlice";


export default function Sidebar() {
    const toggled = useSelector((state) => state.menuState.menuOpen)
    const dispatch = useDispatch()

    return (
      <ul className={toggled ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'} id="accordionSidebar">

        {/* <!-- Sidebar - Brand --> */}
        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-laugh-wink"></i>
          </div>
          <div className="sidebar-brand-text mx-3">Taski <sup>224</sup></div>
        </a>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/* <!-- Nav Item - Dashboard --> */}
        <li className="nav-item">
          <Link className="nav-link" to="/">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Tableau de bord</span></Link>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">
          Utilisateurs
        </div>

          <li className="nav-item">
              <Link className="nav-link" to="/users">
                  <i className="fas fa-fw fa-list"></i>
                  <span>Liste</span></Link>
          </li>
          <li className="nav-item">
              <Link className="nav-link" to="/users/edit">
                  <i className="fas fa-fw fa-edit"></i>
                  <span>Ajouter</span></Link>
          </li>

          <hr className="sidebar-divider" />

          <div className="sidebar-heading">
              Courses
          </div>

          <li className="nav-item">
              <Link className="nav-link" to="/rides">
                  <i className="fas fa-fw fa-car"></i>
                  <span>Liste</span></Link>
          </li>



        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">
          Statistiques
        </div>

        {/* <!-- Nav Item - Charts --> */}
        <li className="nav-item">
          <Link className="nav-link" to="/statistics">
            <i className="fas fa-fw fa-chart-area"></i>
            <span>Vue d'ensemble</span></Link>
        </li>

        {/* <!-- Nav Item - Tables --> */}
        {/*<li className="nav-item">*/}
        {/*  <a className="nav-link" href="tables.html">*/}
        {/*    <i className="fas fa-fw fa-table"></i>*/}
        {/*    <span>Tables</span></a>*/}
        {/*</li>*/}

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider d-none d-md-block" />

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        <div className="text-center d-none d-md-inline">
          <button onClick={() => dispatch(toggleOpen())} className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>

      </ul>);
}