import React, {useEffect} from 'react';

//Navigation
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';

import CardInfo from '../../components/Cards/Info';
import PageHeading from '../../components/PageHeading';
import {useDispatch, useSelector} from "react-redux";
import {getEntitiesCountRequest} from "../../features/stats/statsSlice";
import {getDriversRequest, selectDriver} from "../../features/user/driversSlice";
import {Button} from 'reactstrap'

import GoogleMapReact from 'google-map-react';
import {FaBicycle} from "react-icons/fa6";
import CardBasic from "../../components/Cards/Basic";
import api from "../../config/api";


const defaultProps = {
  center: {
    lat: 9.600219778570859,
    lng: -13.645307959318659
  },
  zoom: 11
};
function Dashboard() {
  let userCount = useSelector((state) => state.statsState.userCount);
  let rideCount = useSelector((state) => state.statsState.rideCount);
  let driverCount = useSelector((state) => state.statsState.driverCount);
  let clientCount = useSelector((state) => state.statsState.clientCount);
  let drivers = useSelector((state) => state.driversState.drivers);
  let selectedDriver = useSelector((state) => state.driversState.selectedDriver);
  const dispatch = useDispatch()

  useEffect(function () {
    document.getElementById('body').className = 'page-top'
  }, [])

  useEffect(function () {
    dispatch(getEntitiesCountRequest())
  }, [dispatch])

  useEffect(function () {
    dispatch(getDriversRequest())
  }, [dispatch])


  function onSelectDriver(driver) {
     dispatch(selectDriver(driver))
  }
  function refreshDrivers(driver) {
     dispatch(getDriversRequest())
  }

  const DriverInMap = (driver) =>   (<img onClick={() => onSelectDriver(driver)}
                                        width="15"
                                        src="/images/t-taski.png"
                                        lat={driver.knownPositionResponse.latitude}
                                        lng={driver.knownPositionResponse.longitude}
                                    />);



  return (
      <div>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">

          {/* <!-- Sidebar --> */}
          <Sidebar />
          {/* <!-- End of Sidebar --> */}

          {/* <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">

            {/* <!-- Main Content --> */}
            <div id="content">



              {/* <!-- Topbar --> */}
              <Topbar />
              {/* <!-- End of Topbar --> */}

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">

                {/* <!-- Page Heading --> */}

                <PageHeading title="Tableau de bord" />

                <h3>Quelques chiffres</h3>
                {/* <!-- Content Row --> */}
                <div className="row">
                  <CardInfo title="Nombre d'utilisateurs"
                            icon="users"
                    color="primary"
                            value={userCount}/>

                  <CardInfo title="Nombre de chauffeurs"
                            icon="user"
                    color="success"
                            value={driverCount}/>

                  <CardInfo title="Nombre de clients"
                            icon="user"
                    color="info"
                    value={clientCount} />

                  <CardInfo title="Nombre de course"
                    icon="comments"
                    color="warning"
                            value={rideCount}/>
                </div>
                <div className="row">
                  <div className="col-xl-8 col-lg-6" style={{height: "500px"}} >
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: api.googleMapApiKey }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                    >

                    {drivers && drivers.map((driver, index) => DriverInMap(driver))}

                    </GoogleMapReact>
                  </div>
                  <div className="col-xl-4 col-lg-6">
                    <CardBasic title="Information du chauffeur">
                      <Button color="primary" onClick={refreshDrivers}>Mettre à jour la carte des chauffeurs</Button>
                      <br/>
                      <br/>
                      {selectedDriver && <>
                          <p>Nom: {selectedDriver.firstname}</p>
                          <p>Prénom: {selectedDriver.lastname}</p>
                          <p>Numéro de téléphone: {selectedDriver.phoneNumber.networkExtension}{selectedDriver.phoneNumber.lineNumber}</p>
                       </>
                       }

                       {!selectedDriver && <>
                                     <p>Sélectionner un T sur la carte pour afficher les informations du chauffeur</p>
                                  </>
                                              }
                    </CardBasic>

                  </div>

                </div>

              </div>
              {/* <!-- /.container-fluid --> */}

            </div>
            {/* <!-- End of Main Content --> */}

            {/* <!-- Footer --> */}
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; Taski<sup>224</sup> 2022</span>
                </div>
              </div>
            </footer>
            {/* <!-- End of Footer --> */}

          </div>
          {/* <!-- End of Content Wrapper --> */}

        </div>
        {/* <!-- End of Page Wrapper --> */}

        {/* <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a></div>
    )

}

export default Dashboard;