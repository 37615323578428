import api from "../../config/api";
import axios from "axios";
import authHeaders from "../auth/authHeader";
import handleResponse from "../../config/http-response-handler";


export const rideService = {
    getRides
};

function getRides(pageNumber = 0, pageSize = 10, sortingSpec="creationDate", filterSpec) {
    let url = `${api.url}/admin/rides?page=${pageNumber}&size=${pageSize}&sort=${sortingSpec}&${filterSpec}`;
    return axios.get(url, {timeout: 200000, headers: authHeaders})
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleResponse);
}