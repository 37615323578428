export default function handleResponse(response) {
    const data = response.data;
    if(response.response) {
        response = response.response;
    }
    if (response.status >=400 ) {
        if (response.status === 401) {
            logout();
            window.location.reload(true);
        }

        const error = (data && data.message) || response.statusText || response;
        return Promise.reject(error);
    }
    return data;
}

function logout() {
    localStorage.removeItem('accessToken');
}