import React, {useState} from "react";
import PublicPage from "../PublicPage";
import {Link, withRouter} from "react-router-dom";

import {Button, Form, FormGroup, Input} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {forgetPasswordRequest, resetPasswordRequest} from "../../features/auth/authenticationSlice";

function ResetPassword(props) {
    const passwordResetAsked = useSelector((state) => state.authenticationState.passwordResetAsked)
    const errorMessage = useSelector((state) => state.authenticationState.resetPasswordErrorMessage)
    const dispatch = useDispatch();
    const [state, setState] = useState({});


    function handleChange(e) {
        const {name, value} = e.target;

        setState({...state, [name]: value});
    }


    function handleClick(e) {
        e.preventDefault();
        if (!passwordResetAsked) {
            dispatch(forgetPasswordRequest({phoneNumber: state.phoneNumber}));
        } else {
            dispatch(resetPasswordRequest({
                phoneNumber: state.phoneNumber,
                password: state.password,
                passwordConfirmation: state.passwordConfirmation,
                code: state.code
            }));
        }
    }

    return (
        <PublicPage>
            <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
            <div className="col-lg-6">
                <div className="p-5">
                    <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Mot de passe oublié</h1>
                    </div>
                    <Form className="user">
                        <FormGroup>
                            <Input type="phone" name="phoneNumber" className="form-control form-control-user"
                                   placeholder="Numéro de télephone" onChange={handleChange} required={true}/>
                        </FormGroup>

                        {passwordResetAsked &&
                            <>
                                <FormGroup>
                                    <Input type="password" name="password" className="form-control-user"
                                           placeholder="Nouveau mot de passe" onChange={handleChange} required={true}/>
                                </FormGroup>
                                <FormGroup>
                                    <Input type="password" name="passwordConfirmation" className="form-control-user"
                                           placeholder="Confirmation du mot de passe" onChange={handleChange} required={true}/>
                                </FormGroup>
                                <FormGroup>
                                    <Input type="password" name="code" className="form-control-user"
                                           placeholder="Code de confirmation" onChange={handleChange} required={true}/>
                                </FormGroup>
                            </>
                        }

                        <FormGroup>
                            {errorMessage && <div className={"alert alert-danger"}>{errorMessage}</div>}
                            <Button onClick={handleClick} color="primary" className="btn-user btn-block">
                                Reinitialiser mon mot de passe
                            </Button>

                            <div className="text-center">
                                <Link className="small" to={"/login"}>Se connecter</Link>
                            </div>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        </PublicPage>
    );
}

export default withRouter(ResetPassword);