import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {userService} from "./userService";

export const getDriversRequest = createAsyncThunk('users/drivers/list', async (action) => {
    return await userService.getDrivers()
});

export const driversSlice = createSlice({
    name: 'users',
    initialState: {
        loading: false,
        drivers : [],
        selectedDriver: null,
        errorMessage: null,
    },
    reducers: {
         selectDriver: (state, action) => {
                    state.page = 0;
                    state.selectedDriver = action.payload;
         },
    },
    extraReducers: (builder) => {
        builder.addCase(getDriversRequest.pending, state => {
            state.loading = true;
        });

        builder.addCase(getDriversRequest.fulfilled, (state,action) => {
            state.loading = false;
            state.drivers = action.payload
        });

        builder.addCase(getDriversRequest.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload;
        });
    }
})

export const { selectDriver } = driversSlice.actions;

export default driversSlice.reducer