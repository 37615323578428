import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {userService} from "./userService";

export const getUsersRequest = createAsyncThunk('users/list', async (action) => {
    return await userService.getUsers(action.page, action.pageSize, action.order, action.filter)
});

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        loading: false,
        users : null,
        filter: '',
        order: '',
        pageSize: null,
        page: 0,
        errorMessage: null,
    },
    reducers: {
        applyFilter: (state, action) => {
            state.page = 0;
            state.filter = action.payload;
        },
        setOrder: (state, action) => {
            state.order = action.payload;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
        changePageSize: (state, action) => {
            state.page = 0;
            state.pageSize = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersRequest.pending, state => {
            state.loading = true;
        });

        builder.addCase(getUsersRequest.fulfilled, (state,action) => {
            state.loading = false;
            state.users = action.payload
        });

        builder.addCase(getUsersRequest.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload;
        });
    }
})

export const { applyFilter, setOrder, changePage, changePageSize } = usersSlice.actions;

export default usersSlice.reducer